/* global tns, Modernizr, scrollIt */

window.onload = function() {
  setRequiredDates();
  bindCustomDateFields();
};

// Make dates required if necessary
//  For touch-devices, use the <input type=date>-field
//  For non-touch-devices, use three input-fields to input the date
function setRequiredDates() {
  var datesList = document.querySelectorAll('.form__item--date--required');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var name = dates.getAttribute('name');
    if (Modernizr && Modernizr.touchevents) {
      var completeDate = document.querySelector('#' + name);
      completeDate.setAttribute('required', 'required');
    } else {
      document.querySelector('#' + name + '_1').setAttribute('required', 'required');
      document.querySelector('#' + name + '_2').setAttribute('required', 'required');
      document.querySelector('#' + name + '_3').setAttribute('required', 'required');
    }
  }
}

// For non-touch-devices, use a custom date entry
//  It uses three input-fields to fill the <input type=date>-field
function bindCustomDateFields() {
  var datesList = document.querySelectorAll('.form__item--date');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var dateHelpers = dates.querySelectorAll('.form__control--date-helper');
    var name = dates.getAttribute('name');
    var completeDate = document.querySelector('#' + name);

    for (var j = 0; j < dateHelpers.length; ++j) {
      var dateHelper = dateHelpers[j];
      dateHelper.oninput = function() {
        var day = document.querySelector('#' + name + '_1');
        var month = document.querySelector('#' + name + '_2');
        var year = document.querySelector('#' + name + '_3');

        completeDate.value = year.value + '-' + month.value + '-' + day.value;
      };
    }
  }
}

// Native validation formfields

var formfields = document.querySelectorAll('input[type=text], input[type=email], textarea');
if(formfields ) {
  for (var i = 0; i < formfields.length; i++) {
    formfields[i].addEventListener('blur', function(event) {
      event.target.parentNode.classList.add('interacted');
    }, false);
  }
}

// Scroll down button

var body = document.body;
var toggleMenu = document.querySelector('.js-toggle-menu');
var bar = document.querySelector('.bar');
var scrolldownButton = document.querySelector('.js-scrolldown');
var scrolltoBand = document.querySelector('.js-scrollto');

if (scrolldownButton) {
  if (scrolldownButton.closest !== undefined) {
    scrolldownButton.addEventListener('click', function(e) {
      scrollIt(scrolldownButton.closest('.band').nextElementSibling, 300, 'easeOutQuad');
      e.preventDefault();
    });
  }
}

if (toggleMenu) {
  toggleMenu.addEventListener('click', function (e) {
    body.classList.toggle('show-menu');
    bar.classList.toggle('animate');
    e.preventDefault();
  });
}

// Cardslider

(function() {

  var cardSlider = document.querySelectorAll('.card__slider');

  if (cardSlider) {
    for (var i = 0; i < cardSlider.length; i++) {
      tns({
        useLocalStorage: false, // Do not use local storage, this conflicts with Cookiebot
        container: cardSlider[i],
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        autoHeight: true,
        controls: false,
        nav: true
      });
    }
  }
})();

// Carousel

(function() {
  var carousel = document.querySelectorAll('.carousel');

  if (carousel) {
    for (var i = 0; i < carousel.length; i++) {
      var sliderCarousel = tns({
        useLocalStorage: false, // Do not use local storage, this conflicts with Cookiebot
        container: carousel[i],
        items: 1,
        controls: true,
        slideBy: 1,
        edgePadding: 30,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controlsText: ['', ''],
        nav: false,
        gutter: 10,
        onInit: setSelectedSlide,
        responsive: {
          480: {
            gutter: 50,
            edgePadding: 30
          },
          560: {
            gutter: 100,
            edgePadding: 60
          },
          768: {
            gutter: 1,
            items: 3,
            edgePadding: 0
          }
        }
      });

      if (sliderCarousel) {
        sliderCarousel.events.on('indexChanged', setSelectedSlide);
      }
    }
  }

  function setSelectedSlide(event) {
    for (var i = 0; i < event.slideItems.length; i++) {
      event.slideItems[i].classList.remove('selected');
      event.slideItems[i].classList.remove('before-selected');
      event.slideItems[i].classList.remove('after-selected');
    }
    event.slideItems[event.index].classList.add('before-selected');
    event.slideItems[event.index + 1].classList.add('selected');
    event.slideItems[event.index + 2].classList.add('after-selected');
  }

})();

// Toggle FAQ
(function () {
  var faqItems = document.querySelectorAll('.js-toggle-faq');
  for (var i = 0; i < faqItems.length; i++) {
    faqItems[i].addEventListener('click', function(e){
      this.closest('.faq__item').classList.toggle('not-collapsed');
      e.preventDefault();
    });
  }
})();

// Polyfill for Element.closest()
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };

// News slider 
    document.addEventListener("DOMContentLoaded", function () {
      document.querySelectorAll('.news-slider').forEach(elm => {
          function getMax() {
              var rectMain = document.getElementsByTagName('main')[0].getBoundingClientRect()
              var childElementCount = elm.firstElementChild.childElementCount
              var mainWidth = rectMain.width
              var offsetLeft = elm.getBoundingClientRect().left - rectMain.left;
              var maxItems = Math.floor((mainWidth - offsetLeft) / 360)
  
              var min = childElementCount - maxItems
              if (min == childElementCount) min -= 1
              if(min < 0) min = 0
              return min * 360 * -1
          }
  
          function getTransform() {
              const regex = /translate\(([-]?[\d.]+)px\)/;
              var output = regex.exec(elm.firstElementChild.style.transform);
  
              if (!output) return null
              return parseFloat(output[1])
          }
  
          function setTransform(newTransform) {
              elm.firstElementChild.style.transition = `transform 400ms cubic-bezier(0.25, 1, 0.5, 1) 0s`
              elm.firstElementChild.style.transform = `translate(${newTransform}px)`
          }
  
          function getX(event) {
              if (event.type.startsWith('mouse'))
                  return event.x;
              else
                  return event.touches[0].pageX
          }
  
          addEventListener("resize", (event) => {
              var max = getMax()
              var currentTransform = getTransform();
              if (currentTransform) {
                  var newTransform = currentTransform;
                  if (currentTransform < max) newTransform = max
  
                  elm.firstElementChild.style.transform = `translate(${newTransform}px)`
              }
              disablePrevElm()
              disableNextElm()
          })
  
          var elmBand = elm.parentElement.parentElement
          var elmPrev = elmBand.querySelector('.pagination-icon-prev')
          var elmNext = elmBand.querySelector('.pagination-icon-next')
  
          function disableNextElm() {
              var max = getMax()
              var currentTransform = getTransform() ?? 0;
  
              elmNext.classList.remove('disabled')
              if (currentTransform == max)
                  elmNext.classList.add('disabled')
          }
          disableNextElm()
  
          function disablePrevElm() {
              var currentTransform = getTransform() ?? 0;
  
              elmPrev.classList.remove('disabled')
              if (currentTransform == 0)
                  elmPrev.classList.add('disabled')
          }
          disablePrevElm()
  
          elmNext.addEventListener('click', function () {
              if (elmNext.classList.contains('disabled')) return
              elmPrev.classList.remove('disabled')
              var currentTransform = getTransform() ?? 0;
              setTransform(currentTransform - 360)
              disableNextElm()
          })
  
          elmPrev.addEventListener('click', function () {
              if (elmPrev.classList.contains('disabled')) return
              elmNext.classList.remove('disabled')
              var currentTransform = getTransform() ?? 0;
              setTransform(currentTransform + 360)
              disablePrevElm()
          })
  
          elm.firstElementChild.addEventListener('transitionend', function () {
              elm.firstElementChild.style.transition = ``
          })
  
          function onMove(event) {
              var currentX = getX(event);
              document.body.style.userSelect = 'none'
  
              function elmMouseMove(event) {
                  var currentTransform = getTransform() ?? 0;
                  var x = getX(event)
  
                  elm.firstElementChild.style.transform = `translate(${currentTransform + x - currentX}px)`
                  currentX = x
              }
  
              function elmMouseUp(event) {
                  document.body.style.userSelect = ''
  
                  document.removeEventListener('mousemove', elmMouseMove)
                  document.removeEventListener('touchmove', elmMouseMove)
  
                  document.removeEventListener('mouseup', elmMouseUp)
                  document.removeEventListener('touchend', elmMouseUp)
  
                  var currentTransform = getTransform();
                  if (currentTransform) {
                      var max = getMax()
                      var newTransform = 0;
  
                      if (currentTransform > 0) newTransform = 0
                      else if (currentTransform < max) newTransform = max
                      else newTransform = Math.round(currentTransform / 360) * 360
  
                      setTransform(newTransform)
                      disablePrevElm()
                      disableNextElm()
                  }
              }
  
              document.addEventListener('mousemove', elmMouseMove)
              document.addEventListener('touchmove', elmMouseMove)
  
              document.addEventListener('mouseup', elmMouseUp)
              document.addEventListener('touchend', elmMouseUp)
          }
  
          elm.addEventListener('touchstart', onMove)
          elm.addEventListener('mousedown', onMove)
      })
  
      function toggleMenu(event) {
          if(window.innerWidth > 599) return
          event.preventDefault()
          event.stopPropagation()
          if (event.target.closest('.submenu') != null) return
          var currentElm = event.currentTarget.closest('.menu__item')
          if(currentElm.classList.contains('active')){
              currentElm.classList.remove('active')
          }else{
              currentElm.classList.add('active')
          }    
      }
  
      document.querySelectorAll('.menu__item:not(.menu__item_mail):not(.menu__item_phone) .menu__item-toggle').forEach(elm => elm.addEventListener('click', toggleMenu))
      document.querySelector('.menu__item_phone').addEventListener('click', toggleMenu)
      document.querySelector('.menu__item_mail').addEventListener('click', toggleMenu)
  })